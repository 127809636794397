import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import meta_5aLvpOrsZz from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/meta.mjs";
import i18n_yfWm7jX06p from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_eTVJQYlCmx from "/usr/src/app/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import scrollbehaviour_client_iEH8Vhn7ng from "/usr/src/app/plugins/scrollbehaviour.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  meta_5aLvpOrsZz,
  i18n_yfWm7jX06p,
  plugin_eTVJQYlCmx,
  chunk_reload_client_UciE0i6zes,
  scrollbehaviour_client_iEH8Vhn7ng
]