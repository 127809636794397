<script lang="ts" setup>
import { GetNavigationQuery } from "@/types/graphql";
import Logo from "@/assets/integer-logo.svg?component";
import Dots from "@/assets/icons/dots.svg?component";

const props = defineProps<{
  navigationItems: GetNavigationQuery | null;
}>();

const emit = defineEmits<{
  (e: "changeRoute", link: string): void;
}>();

// get pages for navigation
// const navigationItems = computed(() =>
//   pages.value?.pages?.data.map((page: PageEntity) => {
//     return {
//       name: page.attributes?.Name,
//       slug: page.attributes?.Slug,
//     };
//   })
// );

// get locale for language switch
const { locale, setLocaleCookie, localeCodes, locales, setLocale } = useI18n();
const localeRoute = useLocaleRoute();
const switchLocalePath = useSwitchLocalePath();
const router = useRouter();

const menu = ref(false);

watch(menu, () => {
  const body = document.querySelector("body");

  if (menu.value) {
    body!.style.overflow = "hidden";
  } else {
    body!.style.overflow = "auto";
  }
});

const localization = useLocalization();

const route = useRoute();

const slugs = computed(() => {
  let slug = route.params.slug;
  if (Array.isArray(slug)) {
    slug = slug.filter(Boolean);
  }
  return Array.isArray(slug) ? slug : [slug];
});

function getDomain(localeParam: "de" | "en") {
  return locales.value.find((locale) => locale.code === localeParam)?.domain;
}

function getUrl(localeParam: "de" | "en") {
  return `${getDomain(localeParam)}/${localization.value[localeParam].pathMatch.join('/')}`
}

async function handleLocaleChange(clickEvent: MouseEvent, localeParam: "de" | "en") {
  if (locale.value !== localeParam) {
    await navigateTo(getUrl(localeParam), {external : true})
  }
}

const { t } = useI18n({
  useScope: "local",
});
</script>

<i18n lang="json">
{
  "en": {
    "b2b_ecommerce": "B2B experts for e-commerce",
  },
  "de": {
    "b2b_ecommerce": "B2B E-Commerce mit System",
  }
}
</i18n>

<template>
  <header class="module-header">
    <div
      class="flex justify-between items-center h-17 font-semibold py-5.2 gap-8 text-nav-links outer-space"
    >
      <nuxt-link
        :to="localeRoute(`/`)"
        class="z-50 transition-[filter] duration-500 ease-in-out logo"
        @click="$emit('changeRoute', '/')"
      >
        <Logo />
      </nuxt-link>
      <div
        class="hidden xl:grow xl:flex xl:justify-center xl:pr-20 2xl:pr-40"
      >
        <h2
          class="max-w-[8.8rem]"
        >
          {{ t("b2b_ecommerce") }}
        </h2>
      </div>
      <nav class="main-navigation ml-auto hidden sm:block">
        <ul class="flex gap-8">
          <li
            v-for="item in navigationItems?.mainNavigation?.data?.attributes
              ?.pages?.data"
            :key="item.attributes?.Slug"
            class="navigation-item"
          >
            <nuxt-link
              :to="localeRoute(`/${item.attributes?.Slug}`)"
              @click="$emit('changeRoute', `/${item.attributes?.Slug}`)"
              class="flex navigation-link"
              >{{ item.attributes?.Name }}</nuxt-link
            >
          </li>
        </ul>
      </nav>
      <nav class="langswitch">
        <ul class="flex gap-5.2">
          <li
            class="langswitch-item"
            v-for="localeCode in localeCodes"
            :key="localeCode"
          >
            <a
              @click="handleLocaleChange($event, localeCode)"
              class="flex items-center langswitch-link cursor-pointer"
              :title="`Change language to ${localeCode}`"
            >
              <div class="h-2 w-2 mr-1">
                <div
                  class="h-2 w-2 bg-integer-light-green rounded-full"
                  v-if="locale === localeCode"
                ></div>
              </div>
              {{ localeCode }}
            </a>
          </li>
        </ul>
      </nav>
      <div
        @click="() => (menu = true)"
        class="flex items-center justify-center cursor-pointer border w-8 h-8 rounded-full"
        v-if="
          navigationItems?.mainNavigation?.data?.attributes?.pages?.data
            .length !== 0
        "
      >
        <Dots />
      </div>
    </div>

    <transition name="slide">
      <ModuleMenuExpanded
        :navigation-items="navigationItems"
        @changeRoute="
          (link) => {
            menu = false;
            emit('changeRoute', link);
          }
        "
        @close="menu = false"
        v-show="menu"
      />
    </transition>
  </header>
</template>
<style scoped>
.header-white header {
  @apply bg-integer-white text-integer-black;
}
.header-black header {
  @apply bg-integer-black text-integer-white;
}

.slide-leave-active,
.slide-enter-active {
  transition: 500ms ease-in-out;
}
.slide-leave-to,
.slide-enter-from {
  transform: translate(0, -100%);
}

.slide-leave-active {
  overflow-y: hidden;
}
.slide-enter-active {
  overflow-y: auto;
}
</style>
