<script setup lang="ts">
import Logo from "@/assets/integer-logo.svg?component";
import Close from "@/assets/icons/x.svg?component";
import Plus from "@/assets/icons/plus.svg?component";

import { GetNavigationQuery } from "@/types/graphql";

const props = defineProps<{
  navigationItems: GetNavigationQuery | null;
}>();

const emit = defineEmits<{
  (e: "changeRoute", link: string): void;
  (e: "close"): void;
}>();

const localeRoute = useLocaleRoute();

// watch(
//   () => route.params.slug,
//   () => {
//     emit("changeRoute");
//   }
// );
</script>

<template>
  <div
    class="extended-menu fixed top-0 h-full w-full z-30 overflow-auto menu-expanded"
  >
    <div class="grid md:grid-cols-[2fr_1fr] grid-cols-1 min-h-full">
      <div
        class="hidden md:block bg-light-gray min-h-full h-fit w-full outer-space"
      >
        <div class="flex flex-wrap text-s mt-39 gap-x-4 gap-y-20">
          <ul
            class="min-w-[14rem] text-normal"
            v-for="menuItem in navigationItems?.pageCategories?.data"
          >
            <h4 class="p-2 pt-0 pl-0 text-gray">
              {{ menuItem.attributes?.displayName }}
            </h4>
            <li
              class="p-2 pl-0 text-integer-black font-nav-links"
              v-for="route in menuItem.attributes?.pages?.data"
            >
              <NuxtLink
                @click="$emit('changeRoute', route.attributes?.Slug)"
                :to="localeRoute(`/${route.attributes?.Slug}`)"
                class="text-normal"
              >
                {{ route.attributes?.short_title ?? route.attributes?.Name }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>

      <div
        class="bg-integer-black h-full w-full text-off-white outer-space py-5"
      >
        <div class="flex">
          <div
            @click="$emit('close')"
            class="ml-auto flex items-center justify-center cursor-pointer w-8 h-8 border border-off-white rounded-full"
          >
            <Close />
          </div>
        </div>
        <!-- navbar on big viewports -->
        <div class="hidden md:flex flex-col gap-10 mt-25">
          <nuxt-link
            :to="localeRoute(`/${item.attributes?.Slug}`)"
            v-for="item in navigationItems?.mainNavigation?.data?.attributes
              ?.pages?.data"
            class="text-h2 text-gray hover:text-off-white"
            @click="$emit('changeRoute', item.attributes?.Slug)"
          >
            {{ item.attributes?.Name }}
          </nuxt-link>
        </div>

        <!-- navbar on small viewports -->
        <div class="flex md:hidden flex-col gap-10 mt-25">
          <ModuleAccordionItem
            v-for="category in navigationItems?.pageCategories?.data"
            class="menu-accordion text-h2 text-gray"
          >
            <template #header="{ expand }">
              <span :class="{ 'text-off-white': expand }">
                {{ category.attributes?.displayName }}
              </span>
            </template>
            <template #icon="{ expand }">
              <Plus
                class="rotateIcon w-5 h-5"
                :class="{ 'open text-off-white': expand }"
              />
            </template>
            <template #default>
              <div class="flex flex-col mt-7">
                <nuxt-link
                  v-for="categoryItem in category.attributes?.pages?.data"
                  class="hover:text-off-white hover:cursor-pointer ml-4 text-h3 leading-[2.75rem]"
                  :to="`/${categoryItem.attributes?.Slug}`"
                  @click="$emit('changeRoute', categoryItem.attributes?.Slug)"
                >
                  {{
                    categoryItem.attributes?.short_title ??
                    categoryItem.attributes?.Name
                  }}
                </nuxt-link>
              </div>
            </template>
          </ModuleAccordionItem>

          <nuxt-link
            :to="localeRoute(`/${item.attributes?.Slug}`)"
            v-for="item in navigationItems?.mainNavigation?.data?.attributes
              ?.pages?.data"
            class="text-h2 text-gray hover:text-off-white"
            @click="$emit('changeRoute', item.attributes?.Slug)"
          >
            {{ item.attributes?.Name }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.x {
  filter: invert(1);
}

.rotateIcon {
  transform: rotate(0deg);
  transition: transform 200ms linear;
}
.rotateIcon.open {
  transform: rotate(45deg);
  transition: transform 200ms linear;
}
</style>

<style>
.menu-accordion > .accordion-header {
  @apply hover:text-off-white;
}
</style>
