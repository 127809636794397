import { default as _91_46_46_46slug_93pvGVZEAWcBMeta } from "/usr/src/app/pages/[...slug].vue?macro=true";
import { default as datenschutzBjdGXvboxrMeta } from "/usr/src/app/pages/datenschutz.vue?macro=true";
import { default as impressum8rTlfYuIk3Meta } from "/usr/src/app/pages/impressum.vue?macro=true";
import { default as imprintgLccbnSD4KMeta } from "/usr/src/app/pages/imprint.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as privacy_45policy1IN0o4tDn5Meta } from "/usr/src/app/pages/privacy-policy.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93pvGVZEAWcBMeta?.name ?? "slug___de",
    path: _91_46_46_46slug_93pvGVZEAWcBMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93pvGVZEAWcBMeta || {},
    alias: _91_46_46_46slug_93pvGVZEAWcBMeta?.alias || [],
    redirect: _91_46_46_46slug_93pvGVZEAWcBMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93pvGVZEAWcBMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93pvGVZEAWcBMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93pvGVZEAWcBMeta || {},
    alias: _91_46_46_46slug_93pvGVZEAWcBMeta?.alias || [],
    redirect: _91_46_46_46slug_93pvGVZEAWcBMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: datenschutzBjdGXvboxrMeta?.name ?? "datenschutz___de",
    path: datenschutzBjdGXvboxrMeta?.path ?? "/datenschutz",
    meta: datenschutzBjdGXvboxrMeta || {},
    alias: datenschutzBjdGXvboxrMeta?.alias || [],
    redirect: datenschutzBjdGXvboxrMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/datenschutz.vue").then(m => m.default || m)
  },
  {
    name: datenschutzBjdGXvboxrMeta?.name ?? "datenschutz___en",
    path: datenschutzBjdGXvboxrMeta?.path ?? "/datenschutz",
    meta: datenschutzBjdGXvboxrMeta || {},
    alias: datenschutzBjdGXvboxrMeta?.alias || [],
    redirect: datenschutzBjdGXvboxrMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/datenschutz.vue").then(m => m.default || m)
  },
  {
    name: impressum8rTlfYuIk3Meta?.name ?? "impressum___de",
    path: impressum8rTlfYuIk3Meta?.path ?? "/impressum",
    meta: impressum8rTlfYuIk3Meta || {},
    alias: impressum8rTlfYuIk3Meta?.alias || [],
    redirect: impressum8rTlfYuIk3Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressum8rTlfYuIk3Meta?.name ?? "impressum___en",
    path: impressum8rTlfYuIk3Meta?.path ?? "/impressum",
    meta: impressum8rTlfYuIk3Meta || {},
    alias: impressum8rTlfYuIk3Meta?.alias || [],
    redirect: impressum8rTlfYuIk3Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: imprintgLccbnSD4KMeta?.name ?? "imprint___de",
    path: imprintgLccbnSD4KMeta?.path ?? "/imprint",
    meta: imprintgLccbnSD4KMeta || {},
    alias: imprintgLccbnSD4KMeta?.alias || [],
    redirect: imprintgLccbnSD4KMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: imprintgLccbnSD4KMeta?.name ?? "imprint___en",
    path: imprintgLccbnSD4KMeta?.path ?? "/imprint",
    meta: imprintgLccbnSD4KMeta || {},
    alias: imprintgLccbnSD4KMeta?.alias || [],
    redirect: imprintgLccbnSD4KMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index___de",
    path: indexgw2ikJsyhcMeta?.path ?? "/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index___en",
    path: indexgw2ikJsyhcMeta?.path ?? "/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy1IN0o4tDn5Meta?.name ?? "privacy-policy___de",
    path: privacy_45policy1IN0o4tDn5Meta?.path ?? "/privacy-policy",
    meta: privacy_45policy1IN0o4tDn5Meta || {},
    alias: privacy_45policy1IN0o4tDn5Meta?.alias || [],
    redirect: privacy_45policy1IN0o4tDn5Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy1IN0o4tDn5Meta?.name ?? "privacy-policy___en",
    path: privacy_45policy1IN0o4tDn5Meta?.path ?? "/privacy-policy",
    meta: privacy_45policy1IN0o4tDn5Meta || {},
    alias: privacy_45policy1IN0o4tDn5Meta?.alias || [],
    redirect: privacy_45policy1IN0o4tDn5Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/privacy-policy.vue").then(m => m.default || m)
  }
]