<script lang="ts" setup>
import getFooter from "@/graphql/footer.query.graphql";
import { GetFooterQuery } from "~~/types/graphql";

const { locale, t } = useI18n();
const { data } = useAsyncQuery<GetFooterQuery>(getFooter, {
  locale: locale.value,
});
</script>

<i18n lang="json">
{
  "de": {
    "imprintLink": "/impressum",
    "imprint": "Impressum",
    "privacyPolicy": "Datenschutz",
    "privacyPolicyLink": "/datenschutz"
  },
  "en": {
    "imprintLink": "/imprint",
    "imprint": "Imprint",
    "privacyPolicy": "Privacy Policies",
    "privacyPolicyLink": "/privacy-policy"
  }
}
</i18n>

<template>
  <footer class="bg-integer-dark-green module-footer">
    <div
      class="outer-space meta pt-44 text-integer-white text-2xs leading-1.6 pb-10"
    >
      <div class="grid md:grid-cols-2 gap-10">
        <address
          itemprop="address"
          itemscope
          itemtype="https://schema.org/PostalAddress"
          class="grid gap-18 grid-cols-2"
        >
          <div class="font-pixel font-semibold">
            <p itemprop="email">
              <a :href="`mailto:${data?.footer?.data?.attributes?.email}`">{{
                data?.footer?.data?.attributes?.email
              }}</a>
            </p>
            <p itemprop="telephone">
              <a :href="`tel:${data?.footer?.data?.attributes?.phone}`">
                {{ data?.footer?.data?.attributes?.phone }}</a
              >
            </p>
            <p>
              <nuxt-link :to="t('imprintLink')">{{ t("imprint") }}</nuxt-link>
              |
              <nuxt-link :to="t('privacyPolicyLink')">{{
                t("privacyPolicy")
              }}</nuxt-link>
            </p>
          </div>
          <div class="md:block hidden">
            <p itemprop="name" class="company-name">
              {{ data?.footer?.data?.attributes?.company_name }}
            </p>
            <p itemprop="streetAddress" class="street">
              {{ data?.footer?.data?.attributes?.street }}
            </p>
            <p>
              <span itemprop="postalCode" class="zip">{{
                data?.footer?.data?.attributes?.zip
              }}</span
              >&nbsp;
              <span itemprop="addressLocality" class="city">{{
                data?.footer?.data?.attributes?.city
              }}</span
              >,&nbsp;
              <span itemprop="addressCountry" class="country">{{
                data?.footer?.data?.attributes?.country
              }}</span>
            </p>
          </div>
        </address>
        <div class="externals flex flex-wrap items-end md:justify-end gap-x-10">
          <a
            :href="link?.url"
            target="_blank"
            v-for="link in data?.footer?.data?.attributes?.external_links"
            :key="link?.url"
            >{{ link?.name }}</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>
